import React, { useState, Suspense } from 'react';
import { Menu, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import MenuList from '../../router/menuList'
import Routes from '../../router/routes'

export default function App() {
  const [current, setCurrent] = useState(null);

  const navigate = useNavigate()
  const onClick = (e) => {
    navigate(e.key)
    setCurrent(e.key)
  };

  return (
    <>
      <Menu onClick={onClick} theme="dark" selectedKeys={[current]} mode="horizontal" items={MenuList} />
      <Suspense fallback={<>...</>}>
        <Routes />
      </Suspense>
      <p><a href="https://beian.miit.gov.cn/">辽ICP备2023004876号-1</a></p>
    </>
  );
}
