import React from 'react'
import { useRoutes } from 'react-router-dom'

const FutMail = React.lazy(() => import('../pages/mail'))
const StockMail = React.lazy(() => import('../pages/stock/email'))
const History = React.lazy(() => import('../pages/fut/history'))
const Hold = React.lazy(() => import('../pages/fut/hold'))
const Utils = React.lazy(() => import('../pages/utils'))
const Calculate = React.lazy(() => import('../pages/calculate'))
const ETF = React.lazy(() => import('../pages/etf'))


// Brower LocalStoreage 没有域名token 跳转到login

export default function () {
  return useRoutes([
    {
      label: 'FutureMarket',
      key: 'fut_mail',
      path: '/fut_mail',
      element: <FutMail />
    },
    {
      label: 'StockMarket',
      key: 'stock_mail',
      path: '/stock_mail',
      element: <StockMail />
    },
    {
      label: '持仓',
      key: 'hold',
      path: '/hold',
      element: <Hold />
    },
    {
      label: 'ETF',
      key: 'etf',
      path: '/etf',
      element: <ETF />
    },
    {
      label: '历史合约',
      key: 'History',
      path: '/History',
      element: <History />
    },
    {
      label: '工具',
      key: 'utils',
      path: '/utils',
      element: <Utils />
    },
    {
      label: '预测',
      key: 'calculate',
      path: '/calculate',
      element: <Calculate />
    }
  ])
}
