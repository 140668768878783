const menuList = [
  { label: "FutureMarket", key: "fut_mail" },
  { label: "StockMarket", key: "stock_mail" },
  { label: "持仓", key: "hold" },
  { label: "ETF", key: "etf" },
  { label: "历史合约", key: "History" },
  { label: "工具", key: "utils" },
  { label: "预测", key: "calculate" }
];

export default menuList;
